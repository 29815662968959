import axiosIns from '@/libs/axios'

export default {
  getAllFintech: (() => axiosIns.get('accounting/fintech-institution-service/fintech')),
  create: (payload => axiosIns.post('accounting/fintech-institution-service', payload)),
  update: ((payload, id) => axiosIns.put(`accounting/fintech-institution-service/${id}`, payload)),
  getOne: (id => axiosIns.get(`accounting/fintech-institution-service/${id}`)),
  delete: (id => axiosIns.delete(`accounting/fintech-institution-service/${id}`)),
  getAll: (institutionId => axiosIns.get(`accounting/fintech-institution-service/read/${institutionId}`)),
  getAllInstitution: (() => axiosIns.get('accounting/fintech-institution-service/institute')),
}
